import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPatients: builder.query({
            query: () => '/users/patients',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData => {
                const loadedUsers = responseData.map( user => {
                    user.id = user._id
                    return user
                });
                return usersAdapter.setAll(initialState, loadedUsers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return  [
                                { type: 'Patients', id: 'LIST' },
                                ...result.ids.map(id=>({ type: 'Patient', id }))
                            ]
                } else return [{ type: 'Patients', id: 'LIST'}]
            },
        }),
        getPatientRecordByUserNumber: builder.query({
            query: ({ userNumber }) => `/users/patient/${userNumber}`,
            validateStatus: (response, result) => {
              return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData => {
              const { _id, ...rest } = responseData;
              return { ...rest, id: _id };
            },
            providesTags: (result, error, arg) => {
              if (result?.id) {
                // console.log(result.id)
                return [{ type: 'Patient', id: result.id }];
              }
              return [];
            }
        }),
        addNewPatient: builder.mutation({
            query: initialUserData => ({
                url: '/users/patients',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'Patients', id: 'LIST' }
            ]
        }),
        updatePatient: builder.mutation({
            query: initialUserData => {
                return {
                            url: `/users/patient/${initialUserData.number}`,
                            method: 'PATCH',
                            body: {
                                ...initialUserData,
                        }
            }},
            invalidatesTags: (result, error, arg) => {
                return  [
                            { type: 'Patient', id: arg.id }
                        ]
            }
            
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: '/users',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),

        changeUserLanguage: builder.mutation({
            query: initialUserData => ({
                url: `/users/${initialUserData.number}/language`,
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
        }),
        updateLastOpened: builder.mutation({
            query: initialUserData => ({
                url: `/users/${initialUserData.number}/last_opened`,
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
        }),
    }),
})

export const {
    useGetPatientsQuery,
    useGetPatientRecordByUserNumberQuery,
    useAddNewPatientMutation,
    useUpdatePatientMutation,
    useDeleteUserMutation,

    useUpdateLastOpenedMutation,

    useChangeUserLanguageMutation,
} = usersApiSlice

// return the query result object
export const selectUsersResult = usersApiSlice.endpoints.getPatients.select()

// createsmemoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using desctructuring
export const {
    selectAll:  selectUsersAll,
    selectById: selectUserById,
    selectIds:  selectUsersIds
    // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)