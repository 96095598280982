import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE =   {
                            appVersion: 1.00,
                        }

const detailsSlice = createSlice({
    name: "details",
    initialState: INITIAL_STATE,
    reducers:   {
                    setAppVersion: (state, action) => {
                        if(state.appVersion !== action.payload){
                            state.appVersion = action.payload
                        }
                    }
                },
});


export const { 
                setAppVersion
             } = detailsSlice.actions;

export default detailsSlice.reducer;