import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

const authSlice = createSlice({
    name: "auth",
    initialState: { 
                    token: null,
                    language: ""
                },
    reducers: {
        setCredentials: (state, action) => {
            
            const { accessToken } = action.payload
            state.token = accessToken
            
            const decoded = jwtDecode(accessToken);
            state.language = decoded.userInfo.info.language
        },
        setLanguage: (state, action) => {
            state.language = action.payload; // Directly update the language
        },
        logOut: (state, action) => {
            state.token = null
        },
    }
})

export const { setCredentials, logOut, setLanguage } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentLanguage = (state) => state.auth.language