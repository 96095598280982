import styles from './V1Banner.module.css'
// icons
import InlineSvg from 'react-inlinesvg'
import banner from './../../assets/img/by_page/main/banner.svg'
import ellipse from './../../assets/img/by_page/main/ellipse.svg'

const V1Banner = () => {
  return (
          <div className={styles.main}>

                <div className={styles.left}>
                    <h1 className={styles.subtitle}>Shakeshev - Ваш надежный партнер в</h1>
                    <h2 className={styles.title}>IT и Дизайне</h2>
                    <p className={styles.description}>мы создаем результативные и эффективные решения, которые помогают Вашему бизнесу расти, развиваться и оставаться конкурентоспособным на рынке!</p>
                </div>

                <div className={styles.right}>
                    <div className={styles.img}>
                        <InlineSvg className={`selectDisable ${styles.banner}`} src={banner} />
                        <InlineSvg className={styles.ellipse} src={ellipse} />
                    </div>      
                </div>
              
            </div>
  )
}

export default V1Banner