import styles from './Vlayout.module.css'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from "react-router-dom"
// 
import VNav from "./common/VNav"
import VFooter from "./common/VFooter"
// Images
import InlineSVG from 'react-inlinesvg'

// Helmet
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';


const VLayout = () => {
    const isDefaultThemeColor = useSelector(state => state.helmet.theme_color.isDefault);
    const defaultThemeColor = useSelector(state => state.helmet.theme_color.defaultColor);
    const newThemeColor = useSelector(state => state.helmet.theme_color.newColor);

    // TIME
    const [serverTime, setServerTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
        setServerTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = time => {
        const dateString = time.toString();
        const index = dateString.indexOf('(');
        return index !== -1 ? dateString.slice(0, index).trim() : dateString;
    };


    // 
    const content = (
        <>
            {isDefaultThemeColor
                ?   <Helmet>
                    <meta name="theme-color" content={defaultThemeColor} />
                    </Helmet>
                : <Helmet>
                        <meta name="theme-color" content={newThemeColor} />
                    </Helmet>
            }
            
            <div className={styles.v_layout}>
                    {/* <VNav /> */}
                    <Outlet />
                    <VFooter />
                    <div className={styles.main}>
                        <p className={styles.text}>Server Time: {formatTime(serverTime)}</p>
                    
                        <p className={styles.text}>Author: Marat Shakeshev [Version 1.0.234.5678]</p>
                        <p className={styles.text}>(c) ArcTeach. All rights reserved.</p>
                        
                        <p className={`${styles.text} ${styles.caret}`}>
                        {/* C:\{`>`} */}
                        (root) shakeshev@Marats-MacBook-Pro ~ % 
                        </p>  
                    </div>  
            </div>
        </>
    )

    return content
}

export default VLayout