import styles from './VModalFreeConsultation.module.css'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../../customHooks/useScrollBlock";
import PopupAppearLayout from "../Layouts/PopupAppearLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
// FORM
import { useForm, Controller } from 'react-hook-form';
import InputMask from "@mona-health/react-input-mask";
import { useEffect, useState } from 'react';
// Redux
import { useAddNewPatientMutation, useUpdatePatientMutation } from '../../../../../features/users/usersApiSlice';
// Toastr
import { toast, Zoom} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// Functions
import { convertIsoToDateStr, getAgeEnding, translate } from '../../../../../customHooks/functions';
import { selectCurrentLanguage } from '../../../../../features/auth/authSlice';


const FreeConsultation = ({  }) => {
    const language = useSelector(selectCurrentLanguage)

    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   
    
    const notifyUpdate = () => {
        toast.success(translate("dataUpdated", language), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
            });
    };
    // ///////////////////////////////
    // Toastr
    const notify = () => {
        toast.success(translate("patientCreated", language), {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
            });
      };
    
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            
                

            
        </PopupAppearLayout>
    );         
}

export default FreeConsultation;
