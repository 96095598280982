import { ModalController } from 'react-modal-global';

// Login
// import VModalLogin from './Login/VModalLogin';
// import VModalPatientCreateEdit from './Patient/VModalPatientCreateEdit'
// import VModalPatientRecordAdd from './Patient/VModalPatientRecordAdd';

const Modal_1 = new ModalController({
  defaultParams: {},
  components: {
                // VModalLogin,
                // VModalPatientCreateEdit,
                // VModalPatientRecordAdd,

                
              },
});

export default Modal_1;
