import styles from './VMain.module.css'
// Components
import V1Banner from './V1Banner'
import FreeConsultation from '../../common/FreeConsultation'
// Images
import _1web11 from './../../assets/img/by_page/main/_1web11www.png'
import _1web12 from './../../assets/img/by_page/main/_1web12crm.png'
import _1web21 from './../../assets/img/by_page/main/_1web21seo.png'

import _2int1api from './../../assets/img/by_page/main/_2int1api.png'
import _2int2pay from './../../assets/img/by_page/main/_2int2pay.png'
import arrowBtn from './../../assets/img/by_page/main/arrow-btn.svg'

import _3chat11gpt from './../../assets/img/by_page/main/_3chat11gpt.png'
import _3chat12insta from './../../assets/img/by_page/main/_3chat12insta.png'
import _3chat21tg from './../../assets/img/by_page/main/_3chat21tg.png'
import _3chat22wa from './../../assets/img/by_page/main/_3chat22wa.png'

import _4mob11android from './../../assets/img/by_page/main/_4mob11android.png'
import _4mob12ios from './../../assets/img/by_page/main/_4mob12ios.png'
import _4mob21pwa from './../../assets/img/by_page/main/_4mob21pwa.png'

import _5design11uxui from './../../assets/img/by_page/main/_5design11uxui.png'
import _5design21_2d from './../../assets/img/by_page/main/_5design21_2d.png'
import _5design22_3d from './../../assets/img/by_page/main/_5design22_3d.png'

const Title = ({title}) => {
  return <h2 className={styles.title}>{title}</h2>
}

const Description = ({description}) => {
  return <p className={styles.description}>{description}</p>
}
const CourseImageHalf = ({img, alt}) => {
  return  <div className={styles.img_wrapper_half}>
            <img className={styles.pic} src={img} alt={alt} />
            <img className={styles.arrow} src={arrowBtn} alt='arrow button' />
          </div>
}
const CourseImageFull = ({img, alt}) => {
  return  <div className={styles.img_wrapper_full}>
            <img className={styles.pic} src={img} alt={alt} />
            <img className={styles.arrow} src={arrowBtn} alt='arrow button' />
          </div>
}



const VMain = () => {
  return (
      <>
          <V1Banner />
          {/* Веб разработка */}
          <section className={styles.section}>
              <div className={styles.left}>
                  <Title title='Веб разработка' />
                  <Description description='Мы создаем веб сайты любой сложности, которые полностью соответствуют потребностям Вашего бизнеса! Наша команда предлагает не только разработку уникальных сайтов, но и интеграцию CRM-систем для эффективного управления клиентскими данными. Кроме того, мы предоставляем услуги SEO-продвижения, чтобы Ваши клиенты могли легко найти Вас в поисковых системах.' />
              </div>
              <div className={styles.right}>
                  <div className={styles.row}>
                      <CourseImageHalf img={_1web11} alt='web site image' />
                      <CourseImageHalf img={_1web12} alt='web site image' />
                      
                  </div>
                  <div className={styles.row}>
                      <CourseImageFull img={_1web21} alt='web site image' />
                  </div>
              </div>
          </section>

          {/* Интеграции */}
          <section className={styles.section}>
              <div className={styles.left}>
                  <Title title='Интеграции' />
                  <Description description='Мы предоставляем комплексные решения для интеграции, включая как разработку, так и интеграцию API, что позволяет обеспечить бесшовное взаимодействие между Вашими системами и сторонними сервисами, а также для мобильных приложений. Мы также настраиваем онлайн-платежи, что упрощает процесс расчетов и делает его более доступным для Ваших клиентов.'/>
              </div>
              <div className={styles.right}>
                  <div className={styles.row}>
                      <CourseImageFull img={_2int1api} alt='web site image' />
                  </div>
                  <div className={styles.row}>
                      <CourseImageFull img={_2int2pay} alt='web site image' />
                  </div>
              </div>
          </section>

          {/* Чат-боты */}
          <section className={styles.section}>
              <div className={styles.left}>
                  <Title title='Чат-боты' />
                  <Description description='Мы разрабатываем чат-ботов для популярных платформ, таких как Instagram, WhatsApp и Telegram. Эти боты автоматизируют взаимодействие с пользователями, отвечают на часто задаваемые вопросы, обрабатывают заявки и обеспечивают круглосуточную поддержку, создавая удобный и быстрый канал связи для Ваших клиентов.'/>
              </div>
              <div className={styles.right}>
                  <div className={styles.row}>
                      <CourseImageHalf img={_3chat11gpt} alt='web site image' />
                      <CourseImageHalf img={_3chat12insta} alt='web site image' />
                  </div>
                  <div className={styles.row}>
                      <CourseImageHalf img={_3chat21tg} alt='web site image' />
                      <CourseImageHalf img={_3chat22wa} alt='web site image' />
                  </div>
              </div>
          </section>

          {/* Мобильные приложения */}
          <section className={styles.section}>
              <div className={styles.left}>
                  <Title title='Мобильные приложения' />
                  <Description description='Мы создаем мобильные приложения, которые работают на любых устройствах: прогрессивные веб-приложения (PWA), приложения для iOS и Android. Разработка включает оптимизацию под каждую платформу для обеспечения стабильной и быстрой работы, удобного интерфейса и легкого доступа к функциям, что позволяет привлечь больше пользователей и сделать взаимодействие с продуктом максимально комфортным.'/>
              </div>
              <div className={styles.right}>
                  <div className={styles.row}>
                      <CourseImageHalf img={_4mob11android} alt='web site image' />
                      <CourseImageHalf img={_4mob12ios} alt='web site image' />
                  </div>
                  <div className={styles.row}>
                      <CourseImageFull img={_4mob21pwa} alt='web site image' />
                  </div>
              </div>
          </section>

          {/* Дизайн */}
          <section className={styles.section}>
              <div className={styles.left}>
                  <Title title='Дизайн' />
                  <Description description='Мы предлагаем полный спектр услуг дизайна для создания визуально привлекательных и удобных решений. Включаем UX/UI-дизайн для интуитивного интерфейса, 2D-анимацию, которая добавляет динамику и оживляет контент, а также 3D-моделирование, позволяющее детально представить сложные объекты. Этот подход помогает формировать целостный визуальный образ, который привлекает внимание, улучшает взаимодействие пользователей и делает продукт более запоминающимся.'/>
              </div>
              <div className={styles.right}>
                  <div className={styles.row}>
                      <CourseImageFull img={_5design11uxui} alt='web site image' />
                  </div>
                  <div className={styles.row}>
                      <CourseImageHalf img={_5design21_2d} alt='web site image' />
                      <CourseImageHalf img={_5design22_3d} alt='web site image' />
                  </div>
              </div>
          </section>

          {/* <FreeConsultation /> */}
      </>
  )
}

export default VMain